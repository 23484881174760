import MyRouter from './MyRouter';

function App() {
  return (
    <>
      <MyRouter />
    </>
  );
}

export default App;
